import { useState, useRef } from 'react'
import styles from '../index.module.scss'

const Accordion = ({ footerNavLinks }) => {
  const [openIndex, setOpenIndex] = useState(null)
  const sectionRefs = useRef([])

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => {
      const newIndex = prevIndex === index ? null : index
      if (newIndex !== null) {
        setTimeout(() => {
          sectionRefs.current[newIndex].scrollIntoView({ behavior: 'smooth' })
        })
      }
      return newIndex
    })
  }

  return (
    <div className={styles.accordion}>
      {footerNavLinks?.map((section, index) => (
        <div
          key={index}
          className={`${styles.accordionContentWrapper} ${
            openIndex === index ? styles.collapse : ''
          } ${
            openIndex === index || index !== footerNavLinks.length - 1
              ? styles.withBorder
              : ''
          }`}
          ref={(el) => (sectionRefs.current[index] = el)}
        >
          <div
            className={styles.control}
            onClick={() => toggleAccordion(index)}
          >
            <h4 className={styles.title}>{section?.title}</h4>
            <i className="icon icon-angle-down"></i>
          </div>

          {openIndex === index && (
            <ul>
              {section?.links?.map((link, linkIndex) => (
                <li className={styles.accordionContent} key={linkIndex}>
                  <a href={link?.url}>{link?.text}</a>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  )
}

export default Accordion
