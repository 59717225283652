const envUrl = process.env.NEXT_PUBLIC_MAIN_URL2

export const footerNavLinks = [
  {
    title: 'ÜRÜNLERİMİZ',
    links: [
      { url: `${envUrl}/is-ilanlari`, text: 'İş İlanları' },
      { url: `${envUrl}/hizmet`, text: 'Hizmet Al' },
      { url: `${envUrl}/hizmetler`, text: 'Hizmet Ver' },
      { url: `${process.env.NEXT_PUBLIC_MAIN_URL}/blog/`, text: 'Blog' },
      {
        url: `${process.env.NEXT_PUBLIC_MAIN_URL}/blog/mesleki-gelisim/`,
        text: 'Meslek Rehberi',
      },
      { url: `${envUrl}/maas-hesaplama`, text: 'Maaş Hesaplama' },
    ],
  },
  {
    title: 'KURUMSAL',
    links: [
      { url: `${envUrl}/hakkimizda`, text: 'Hakkımızda' },
      {
        url: `${envUrl}/hakkimizda/isverenlere-ozel`,
        text: 'İşverenlere Özel',
      },
      {
        url: `${envUrl}/hakkimizda/is-arayanlara-ozel`,
        text: 'İş Arayanlara Özel',
      },
      { url: `${envUrl}/iletisim`, text: 'İletişim' },
    ],
  },
  {
    title: 'KURALLAR VE KOŞULLAR',
    links: [
      { url: `${envUrl}/sozlesmeler`, text: 'Sözleşmeler' },
      { url: `${envUrl}/cerez-politikasi`, text: 'Çerez Politikası' },
      {
        url: `${envUrl}/guvenli-is-arama-rehberi`,
        text: 'Güvenli İş Arama Rehberi',
      },
    ],
  },
  {
    title: 'YARDIM',
    links: [
      {
        url: `${envUrl}/yardim`,
        text: 'Yardım Merkezi',
      },
      {
        url: `${envUrl}/yardim/islem-rehberi`,
        text: 'İşlem Rehberi',
      },
    ],
  },
]

export const iLabBrands = [
  {
    name: 'Arabam.com',
    url: 'https://www.arabam.com/',
  },
  {
    name: 'ChemOrbis',
    url: 'https://www.chemorbis.com/tr/',
  },
  {
    name: 'Cimri.com',
    url: 'https://www.cimri.com/',
  },
  {
    name: 'Emlakjet',
    url: 'https://www.emlakjet.com/',
  },
  {
    name: 'Hangikredi.com',
    url: 'https://www.hangikredi.com/',
  },
  {
    name: 'Kariyer.net',
    url: 'https://www.kariyer.net/',
  },
  {
    name: 'Neredekal.com',
    url: 'https://www.neredekal.com/',
  },
  {
    name: 'Sigortam.net',
    url: 'https://www.sigortam.net/',
  },
  {
    name: 'SteelOrbis',
    url: 'https://tr.steelorbis.com/',
  },
]

export const socialLinks = [
  {
    dataTest: 'facebook-link',
    href: 'https://www.facebook.com/isinolsunapp',
    ariaLabel: 'facebook',
    iconClass: 'icon-facebook',
  },
  {
    dataTest: 'x-link',
    href: 'https://x.com/isinolsunapp',
    ariaLabel: 'twitter',
    iconClass: 'icon-x',
  },
  {
    dataTest: 'instagram-link',
    href: 'https://www.instagram.com/isinolsunapp',
    ariaLabel: 'instagram',
    iconClass: 'icon-instagram',
  },
]
