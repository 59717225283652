import Head from 'next/head'
import { useEffect, useState } from 'react'
import { useGlobalContext } from 'contexts'

const PageHead = (props) => {
  const {
    canonical,
    nextCanonical,
    prevCanonical,
    isDisabledTitlePart,
    noIndexFollow,
    noIndexNoFollow,
    pageType,
    preloadLogo,
  } = props

  const [addScript, setAddScript] = useState(false)
  const { setGtmLoaded } = useGlobalContext()

  useEffect(() => {
    //setAddScript(true)
    if (!pageType) {
      dataLayer.push({ page_type: 'diger' })
    }
    addScriptControl()
  }, [])

  useEffect(() => {
    if (addScript) setGtmLoaded(true)
  }, [addScript])

  const addScriptControl = () => {
    if ('requestIdleCallback' in global) {
      global.requestIdleCallback(
        (deadline) => {
          const time = deadline.timeRemaining()
          if (time > 10 || deadline.didTimeout) {
            setAddScript(true)
            return
          } else {
            addScriptControl()
          }
        },
        { timeout: 2000 }
      )
    } else {
      setAddScript(true)
    }
  }

  const titleCalc = () => {
    if (props.title) {
      if (isDisabledTitlePart) return props.title
      else return props.title + ' ' + process.env.NEXT_PUBLIC_TITLE_PART
    }

    return 'İşin Olsun'
  }

  const descCalc = () => {
    if (props.description) return props.description

    return 'Binlerce ilan içerisinden evinin yakınındaki o işi hızlıca bul.'
  }

  const title = titleCalc()
  const description = descCalc()

  return (
    <>
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `var dataLayer = dataLayer || [];
            var googletag = googletag || {};
            googletag.cmd = googletag.cmd || [];`,
          }}
        ></script>
        <style
          dangerouslySetInnerHTML={{
            __html: `.async-hide {
              opacity: 0 !important;
            }`,
          }}
        ></style>
        {addScript && (
          <>
            {process.env.NEW_RELIC_ENABLED == 'true' && (
              <script
                type="text/javascript"
                src={`${process.env.NEXT_PUBLIC_MAIN_URL2}/newrelicsnippet.js`}
              />
            )}
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(d,t){
              var e = d.createElement(t),
                  s = d.getElementsByTagName(t)[0];
                  e.src = "https://wps.relateddigital.com/relatedpush_sdk.js?ckey=8527C50CF8754F9C8226669D91008AEE&aid=${process.env.NEXT_PUBLIC_RELATEDAID}";
                  e.async = true;
                  s.parentNode.insertBefore(e,s);
          }(document,"script"));`,
              }}
            ></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `!function(e,a,n,d,g){e.dengage=e.dengage||function(){(e.dengage.q=e.dengage.q||[]).push(arguments)},
                d=a.getElementsByTagName("head")[0],
                (g=a.createElement("script")).async=1,
                g.src="https://pcdn.dengage.com/p/push/494/e706e97d-b3f7-d252-b5d1-6af9e425c9ac/dengage_sdk.js",
                d.appendChild(g)}(window,document); dengage('initialize');`,
              }}
            ></script>

            {/* Google Tag Manager */}
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KNT2Z7K');`,
              }}
            ></script>

            <script
              async="async"
              src="https://www.googletagservices.com/tag/js/gpt.js"
            ></script>
          </>
        )}
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />

        {noIndexFollow && <meta name="robots" content="noindex, follow" />}
        {noIndexNoFollow && <meta name="robots" content="noindex, nofollow" />}
        {!noIndexFollow && !noIndexNoFollow && (
          <meta name="robots" content="index, follow" />
        )}

        {canonical && <link rel="canonical" href={canonical} />}
        {nextCanonical && <link rel="next" href={nextCanonical} />}
        {prevCanonical && <link rel="prev" href={prevCanonical} />}

        <link
          rel="preload"
          as="image"
          href={`${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/${
            preloadLogo ? preloadLogo : 'logo.png'
          }`}
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/logo-og.png`}
        />
        <meta
          name="twitter:image"
          content={`${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/logo-og.png`}
        />

        <meta property="og:site_name" content="isinolsun.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@isinolsunapp" />

        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />

        <meta
          name="facebook-domain-verification"
          content="727bebdev0q68q9lys9tlv2ypc5ebm"
        />

        <link
          rel="apple-touch-icon"
          href="/_next/static/images/touch-icon.png"
        />
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <link
          rel="preconnect"
          href={process.env.NEXT_PUBLIC_CDN_URL}
          crossOrigin="true"
        />
        <link rel="dns-prefetch" href={process.env.NEXT_PUBLIC_CDN_URL} />
        <link rel="manifest" href="/manifest.json" />
      </Head>
      {addScript && (
        <>
          {/* Google Tag Manager (noscript) */}
          <noscript
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KNT2Z7K" height="0" width="0" style="display: none; visibility: hidden"></iframe>',
            }}
          ></noscript>
        </>
      )}
    </>
  )
}

export default PageHead
